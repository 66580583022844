import React, {Component} from 'react';
import CompanyRow from "./CompanyRow";
import FiltersView from "./FiltersView";

class CompanyList extends Component {
    referenceERP;
    state = {
        filter: {
            warehouses: [],
            codes: []
        },
        companies: [],
        warehouses: [],
        codes: [],
    };

    fetchData = async () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);

        if (!(params.get('token_uid') || '').trim().length || !(params.get('token_db') || '').trim().length) {
            throw Error('Authentification invalide');
        }

        // const response = await fetch('/data.json', {
        const response = await fetch('https://middleware-milliet.herokuapp.com/crm/agedBalance', {
            headers: {
                token_uid: params.get('token_uid'),
                token_db: params.get('token_db'),
            }
        });

        const body = await response.json();
        if (response.status !== 200) {
            throw Error('Une erreur est survenue lors de la navigation. Rechargez la page pour réessayer.');
        }

        return body;
    };

    setSearch = (list, query) => {
        if (!query || query.length < 3) {
            return list;
        }

        // @todo implement if response format is ok https://middleware-milliet.herokuapp.com/companies/search/sarl

        query = query.toLowerCase();
        return list.filter(row => {
            return String(row.name).toLowerCase().includes(query) ||
                   String(row.referenceERP2 || row.referenceERP).includes(query);
        });
    };

    setFilters = (list, filter) => {
        return list.filter(row => {
            return (Object.keys(filter.codes).length === 0 || row.salesmanERP in filter.codes) &&
                   (Object.keys(filter.warehouses).length === 0 || row.warehouse in filter.warehouses);
        });
    };

    componentDidMount() {
        let ctx = this;
        ctx.setState({isLoading: true});

        this.fetchData()
            .then(res => {
                let companies  = Object.values(res.companies || []);
                let warehouses = Object.entries(res.warehouses || []);
                let codes = [...new Set(companies.map(obj => obj.salesmanERP || null).sort())];

                ctx.setState({
                    companies: companies,
                    warehouses: warehouses,
                    codes: codes,
                    isLoading: false,
                })
            })
            .catch(err => {
                console.log(err);

                ctx.setState({
                    error: true,
                    isLoading: false,
                    message: err.message
                })
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filter !== this.state.filter) {
            // do something
            console.log("filter", this.state.filter);
        }
    }

    calculateTotals(list) {
        let totalEchu = 0
        let totalNonEchu = 0

        list.forEach(company => {
            totalEchu += company.encoursEchu || 0
            totalNonEchu += company.encoursNonEchu || 0
        })

        return { totalEchu, totalNonEchu }
    }

    render() {
        let list = this.applyFilters(this.state.companies, this.props);

        if (this.props.filterView) {
            return (
                <div className="App">
                    <FiltersView
                        onFilterView={this.props.onFilterView}
                        warehouses={this.state.warehouses}
                        codes={this.state.codes}
                        filter={this.props.filter} />
                </div>
            );
        }

        if (this.state.isLoading === true) {
            return <div className="text-center col-md pt-5">Chargement en cours...</div>
        }

        if (this.state.error === true) {
            return <div className="text-center col-md pt-5">{this.state.message}</div>
        }

        if (list.length === 0) {
            return <div className="text-center col-md pt-5">Aucun résultat trouvé.</div>
        }

        const { totalEchu, totalNonEchu } = this.calculateTotals(list)

        return ( 
            <div className="company-list">
                <div className="company-row total">
                    <div className="company-balance col-12">
                        <div className="row">
                            <div className="company-balance-row col-6 text-center">
                                <div>Total échu</div>
                                <div>{totalEchu.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                            </div>
                            <div className="company-balance-row col-6 text-center">
                                <div>Total à échoir</div>
                                <div>{totalNonEchu.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                            </div>
                        </div>
                    </div>
                </div>

                {list.map((row, index) => {
                    return <CompanyRow
                        key={index}
                        id={row.id}
                        name={row.name}
                        referenceERP2={row.referenceERP2}
                        referenceERP={row.referenceERP}
                        oldestDueDate={row.oldestDueDate}
                        color={row.color}
                        ca={row.ca}
                        encoursEchu={row.encoursEchu}
                        encoursNonEchu={row.encoursNonEchu}
                        warehouse={row.warehouse}
                        salesmanERP={row.salesmanERP}
                    />
                })}
            </div>
        );
    }

    applyFilters(list, props) {
        list = this.setSearch(list, props.search);
        list = this.setFilters(list, props.filter);
        list = this.sortList(list, props.sort);

        return list;
    }

    sortList(list, sortType) {
        let sortFunctions = {
            ratioAsc: function (list) {
                return list.sort((a, b) => a.ca - b.ca);
            },
            ratioDesc: function (list) {
                return list.sort((a, b) => b.ca - a.ca);
            },
            expiredOutstandingDesc: function (list) {
                return list.sort((a, b) => b.encoursEchu - a.encoursEchu);
            },
            expiredOutstandingAsc: function (list) {
                return list.sort((a, b) => a.encoursEchu - b.encoursEchu);
            },
            notExpiredOutstandingDesc: function (list) {
                return list.sort((a, b) => b.encoursNonEchu - a.encoursNonEchu);
            },
            notExpiredOutstandingAsc: function (list) {
                return list.sort((a, b) => a.encoursNonEchu - b.encoursNonEchu);
            },
            oldestDuesAsc: function (list) {
                return list.sort((a, b) => CompanyRow.getDueDays(b.oldestDueDate) - CompanyRow.getDueDays(a.oldestDueDate));
            },
            oldestDuesDesc: function (list) {
                return list.sort((a, b) => CompanyRow.getDueDays(a.oldestDueDate) - CompanyRow.getDueDays(b.oldestDueDate));
            },
            status: function (list) {
                return list.sort((a, b) => ('' + b.color).localeCompare(a.color));
            }
        };

        if (sortType in sortFunctions) {
            return sortFunctions[sortType](list);
        }

        return list;
    }
}

export default CompanyList;
