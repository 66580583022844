import React, {Component} from 'react';

class FiltersView extends Component {
    state = {
        selected: [],
    };

    handleInput = (event) => {
        const name = event.target.name;
        const key = event.target.dataset.key;

        this.props.filter[name][key] = !this.props.filter[name][key];

        if (!this.props.filter[name][key]) {
            delete this.props.filter[name][key];
        }

        this.setState(({ selected }) => ({ selected: { ...selected, key } }));
    }

    render() {
        return (
            <div className="filtersView">
                <div className="col-12">
                    <div className="filter-row">
                        <h3>Entrepôt de rattachement</h3>
                        <div className="filter-values">
                            {this.props.warehouses.map((warehouse) => {
                                var key = warehouse[0];
                                var name = warehouse[1];

                                return (
                                    <div class="input-field">
                                        <label className="form-check-label" htmlFor={'warehouse_' + key}>
                                            <input className="form-check-input" type="checkbox" onChange={this.handleInput} name="warehouses" id={'warehouse_' + key} checked={this.props.filter.warehouses[key]} data-key={key} />
                                            <span class="input-field-selector"></span>
                                            <span class="input-text">{name}</span>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="filter-row">
                        <h3>Commercial</h3>
                        <div className="filter-values">
                            {this.props.codes.map((key) => {
                                return (
                                    <div class="input-field">
                                        <label className="form-check-label" htmlFor={'code_' + key}>
                                            <input className="form-check-input" type="checkbox" onChange={this.handleInput} name="codes" id={'code_' + key} checked={this.props.filter.codes[key]} data-key={key} />
                                            <span class="input-field-selector"></span>
                                            <span class="input-text">{key}</span>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="btn btn-primary fixed-bottom" onClick={e => this.props.onFilterView()}>Afficher les résultats</div>
                </div>
            </div>
        );
    }
}

export default FiltersView;
