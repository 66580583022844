import "./AgedBalance.css";
import React from "react";
import Searchbar from "./components/Searchbar";
import Filters from "./components/Filters";
import CompanyList from "./components/CompanyList";

class AgedBalance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search: null,
            sort: null,
            filter: {
                codes: {},
                warehouses: {},
            },
            showFilterView: false,
        };

        this.onSearch = this.onSearch.bind(this)
        this.onSort = this.onSort.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.onFilterView = this.onFilterView.bind(this)
    }

    onSearch(e) {
        this.setState({search: e.target.value})
    }
    onSort(e) {
        this.setState({sort: e.target.value})
    }
    onFilter(e) {
        this.setState({filter: e.target.value})

    }
    onFilterView() {
        this.setState({showFilterView: !this.state.showFilterView})
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevState.filter !== this.state.filter) {
            // do something
            console.log("[AgedBalance][componentDidUpdate]", this.state.filter);
        // }
    }

    render() {
        return (
            <div className="App">
                <Searchbar onChange={this.onSearch} />

                <Filters
                    onFilter={this.onFilter}
                    filter={this.state.filter}
                    onFilterView={this.onFilterView}
                    onSort={this.onSort} />

                <CompanyList
                    search={this.state.search}
                    sort={this.state.sort}
                    filter={this.state.filter}
                    onFilterView={this.onFilterView}
                    filterView={this.state.showFilterView}
                />
            </div>
        );
    }
}

export default AgedBalance;
