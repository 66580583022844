import React, {Component} from 'react';

class Filters extends Component {
    render() {
        return (
            <div className="filterbar container-fluid">
                <div className="row">
                    <div className="col-6">
                        <span className="font-weight-bold">Trier</span>
                        <select className="form-control col-12" onChange={e => this.props.onSort(e)}>
                            {/*<option value="daysOfVisit">Prochain jour de visite</option>*/}
                            <option value="ratioAsc">Jours CA croissant</option>
                            <option value="ratioDesc">Jours CA décroissant</option>
                            <option value="expiredOutstandingAsc">Encours echus croissant</option>
                            <option value="expiredOutstandingDesc">Encours echus décroissant</option>
                            <option value="notExpiredOutstandingAsc">Encours non echus croissant</option>
                            <option value="notExpiredOutstandingDesc">Encours non echus décroissant</option>
                            <option value="oldestDuesAsc">Jours avant échéance croissant</option>
                            <option value="oldestDuesDesc">Jours avant échéance décroissant</option>
                            <option value="status">Statut</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <span class="filter-btn" onClick={e => this.props.onFilterView()}>
                            Filtrer
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z"></path><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
                            </svg>
                        </span>
                        <div class="glyphicon glyphicon-arrow-right"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Filters;
