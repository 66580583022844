import {Outlet} from "react-router-dom";
import React from "react";

class Layout extends React.Component {
    render() {
        return (
            <div>
                <div className="content">
                    <Outlet />
                </div>
            </div>
        );
    }
}

export default Layout;
